//------------------------------------------------------------------------------------

import { useState } from 'react';
import '../external.scss';
import baseUrl from '../../../scripts/baseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//------------------------------------------------------------------------------------

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: ''
    });

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const isExist = async () => {
        try {
            const res = await axios.get(`${baseUrl}/login/isExist/${data.email}`);
            if (res.data) {
                navigate(
                    '/otp',
                    { state: { email: data.email } }
                );
            } else {
                alert('User does not exists.');
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='external-screen'>
                <div className="frame">
                    <div className="outlined-card">
                        <form action="">
                            <div className="app-title headline-small">Forget Password</div>

                            <div className="input-container">
                                <input type="email" id='email' name='email' className='inputfield' placeholder='Email' maxLength={255} onChange={handleChange} />
                                <div id='email-hint' className="input-hints">&nbsp;</div>
                            </div>

                            <div className="bottom-buttons">
                                <div></div>
                                <button type='button' className='filled-button' onClick={isExist}>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ForgetPassword;

//------------------------------------------------------------------------------------

