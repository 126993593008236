//------------------------------------------------------------------------------------

const calculateDateStatus = (date) => {
	const currentDate = new Date();
	const targetDate = new Date(date);

	const timeDiff = targetDate - currentDate;

	const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
	const months = Math.floor(days / 30);
	let years = Math.floor(months / 12);

	if (timeDiff > 0) {
		const remainingDays = days % 30;
		const remainingMonths = months % 12;

		let sentence = '';
		if (remainingDays > 0) {
			sentence += `${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
		}
		if (remainingMonths > 0) {
			sentence = `${remainingMonths} month${
				remainingMonths > 1 ? 's' : ''
			} ${sentence}`;
		}
		if (years > 0) {
			sentence = `${years} year${years > 1 ? 's' : ''} ${sentence}`;
		}
		if (remainingDays === 0 && remainingMonths === 0 && years === 0) {
			return {
				message: `Will expire tomorrow`,
				className: 'warning-container',
			};
		}
		return {
			message: `Will expire after ${sentence}`,
			className: 'warning-container',
		};
	} else {
		const expiredDays = (Math.abs(days) % 30) - 1;
		const expiredMonths = (Math.abs(months) % 12) - 1;

		let sentence = '';
		if (expiredDays >= 0) {
			sentence += `${expiredDays} day${expiredDays > 1 ? 's' : ''}`;
		}
		if (expiredMonths > 0) {
			sentence = `${expiredMonths} month${
				expiredMonths > 1 ? 's' : ''
			} ${sentence}`;
		}
		if (years - years * 2 - 1 > 0) {
			years = years - years * 2 - 1;
			sentence = `${years} year${years > 1 ? 's' : ''} ${sentence}`;
		}
		if (expiredDays === 0 && expiredMonths === 0 && years === -1) {
			return {
				message: `Expiring today`,
				className: 'warning-container strong',
			};
		}
		if (expiredDays === 1 && expiredMonths === 0 && years === -1) {
			return {
				message: `Expired yesterday`,
				className: 'warning-container strong',
			};
		}
		return {
			message: `Expired ${sentence} ago`,
			className: 'warning-container strong',
		};
	}
};

export default calculateDateStatus;

//------------------------------------------------------------------------------------
