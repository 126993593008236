//------------------------------------------------------------------------------------

import './CheckUncheck.scss';
import { Done, Clear } from '../Icons/Icons';

//------------------------------------------------------------------------------------

const CheckUncheck = ({ isChecked }) => {
    let className = isChecked ? 'check-uncheck-container checked' : 'check-uncheck-container unchecked';

    return (
        <div className={className}>
            {
                isChecked ? <Done /> : <Clear />
            }
        </div>
    )
}

export default CheckUncheck;

//------------------------------------------------------------------------------------
