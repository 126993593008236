//------------------------------------------------------------------------------------

import './Footer.scss';

//------------------------------------------------------------------------------------

const Footer = () => {
	return (
		<div className="footer-container">
			{/* <div className="firm-name label-large">
				Developed By{' '}
				<a
					href="http://msofts.in/"
					target="_blank"
				>
					M-SOFTS DEVELOPMENT
				</a>
				, Bhusawal
			</div> */}
			<div className="copyrights label-medium">
				© All Copyrights Reserved
			</div>
			<a
				className="link label-medium"
				href="https://privacypolicy.bslcatgvid.in"
				target="_blank"
			>
				Privacy Policy
			</a>
		</div>
	);
};

export default Footer;

//------------------------------------------------------------------------------------
