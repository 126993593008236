//------------------------------------------------------------------------------------

import './Payment.scss';

import { Link, Navigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Loading from '../../../components/Loading/Loading';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import paymentQR from '../../../assets/paymentQR.jpeg';

//------------------------------------------------------------------------------------

const Payment = () => {
	document.title = `Payment • Pune Division Catering Vendor Id`;

	const [validRegistration, setValidRegistration] = useState(false);
	const currentScreen = useLocation().pathname;
	const [ownerPayment, setOwnerPayment] = useState();
	const [value, setValue] = useState('');

	const [screenshotFile, setscreenshotFile] = useState();
	const [screenshotFileName, setscreenshotFileName] = useState('');

	const [loading, setloading] = useState(false);

	const { state } = useLocation();

	const getPaymentValues = async () => {
		try {
			const res = await axios.get(`${baseUrl}/payment/values`);
			setValue(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (currentScreen == '/owner/payment') {
			setOwnerPayment(true);
		}

		if (currentScreen == '/vendor/payment') {
			setOwnerPayment(false);
		}

		getPaymentValues();
	}, []);

	const paymentLink = (link) => {
		window.location.href = link;
	};

	const clickUploadScreenshot = () => {
		document.getElementById('screenshot-input').click();
	};

	const getUploadScreenshot = async (event) => {
		setscreenshotFile(event.target.files[0]);
		setscreenshotFileName(event.target.files[0].name);
		document.getElementById('screenshot-input').value = '';
	};

	const uploadScreenShot = async () => {
		setloading(true);

		const { ownerId } = state;
		let payscreenshot = new FormData();
		payscreenshot.append('image', screenshotFile);
		payscreenshot.append('fileName', screenshotFileName);

		if (screenshotFileName == '') {
			alert('Please select payment screenshot first.');
		} else {
			try {
				const screenshotRes = await axios.put(
					`${baseUrl}/owners/payScreenshot/${ownerId}`,
					payscreenshot
				);

				if (screenshotRes.data) {
					alert('Screenshot uploaded successfully.');
					alert('Owner will be able to login after verification.');

					setValidRegistration(true);
				} else {
					alert('Oops, Something went wrong!');
				}
			} catch (err) {
				console.log(err);
			}
		}
		setloading(false);
	};

	return (
		<>
			<div className='payment-screen'>
				<div className='screen-title display-medium '>Payment</div>
				{ownerPayment ? (
					<>
						{/* <div className='steps label-large'>Payment steps</div>
                            <li className='note-text title-medium'>Click on below 'Click to pay' button to pay.</li>
                            <li className='note-text title-medium'>After successfull payment take a screenshot of payment screen.</li>
                            <li className='note-text title-medium'>Then upload screenshot by clicking on 'Upload screenshot' button.</li>
                            <div className='border'></div>

                            <div className='note-text title-medium'>Click below button to pay.</div>
                            <div className='button-container'>
                                <button className='filled-button' onClick={() => paymentLink(value.ownerPayLink)}>Click to pay</button>
                            </div>
                            <div className='border'></div>
                            <div className='note-text title-medium'>After payment upload screenshot here.</div>

                            <div className='button-container'>
                                <input type='file' className='hidden' id='screenshot-input' onChange={(event) => getUploadScreenshot(event)} />
                                <button className='outlined-button' onClick={clickUploadScreenshot}>Select screenshot image</button>
                                <button className='filled-tonal-button' onClick={uploadScreenShot}>Upload screenshot</button>
                            </div> */}

						<div className='qr-container'>
							<div className='instructions-container'>
								<div className='instructions title-large'>Instructions:</div>
								<li className='headline-small'>
									Scan the given QR and complete the payment process.
									<br></br>
									<br></br>
									(दिए गए क्यूआर को स्कैन करें और भुगतान प्रक्रिया को पूरा
									करें।)
								</li>
								<br></br>
								<li className='headline-small'>
									After payment completion take a screenshot of payment
									successful page and send it to WhatsApp number{' '}
									<b>+91 973-000-4062</b>
									<br></br>
									<br></br>
									(भुगतान पूरा होने के बाद भुगतान सफल पृष्ठ का स्क्रीनशॉट लें और
									इसे व्हाट्सएप नंबर <b>+91 973-000-4062</b> पर भेजें)
								</li>

								<Link to='/home'>
									<button className='outlined-button'>Back</button>
								</Link>
							</div>
							<img src={paymentQR} />
						</div>
					</>
				) : (
					<>Vendor's payment</>
				)}
			</div>

			{loading && <Loading />}
			{validRegistration && (
				<Navigate
					replace
					to='/home'
				/>
			)}
		</>
	);
};

export default Payment;

//------------------------------------------------------------------------------------
