//------------------------------------------------------------------------------------

const unauthorizedRedirection = () => {
    if (localStorage.getItem('owner') == null || localStorage.getItem('owner') == '') {
        localStorage.clear();
        window.location.replace('/');
    }
}

export default unauthorizedRedirection;

//------------------------------------------------------------------------------------