//------------------------------------------------------------------------------------

const auth = (status) => {
	if (status === 'UNAUTHORIZED') {
		localStorage.setItem('owner', '');
		window.location.replace('/');
		// alert('Session timeout, please login again.');
		return false;
	} else {
		return true;
	}
};

export default auth;

//------------------------------------------------------------------------------------
