//------------------------------------------------------------------------------------

import '../internal.scss';
import './Vendors.scss';

import { CheckCircle, Clear } from '../../../components/Icons/Icons';
import baseUrl, { adminBaseUrl } from '../../../scripts/baseUrl';
import { useEffect, useState } from 'react';

import Banner from '../../../components/Banner/Banner';
import Buffer from '../../../components/Buffer/Buffer';
import CheckUncheck from '../../../components/CheckUncheck/CheckUncheck';
import Fallback from '../../../components/Fallback/Fallback';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { Link } from 'react-router-dom';
import Navigation from '../../../components/Navigation/Navigation';
import Pagination from '../../../components/Pagination/Pagination';
import { Refresh } from '../../../components/Icons/Icons';
import auth from '../../../scripts/auth';
import axios from 'axios';
import calculateDateStatus from '../../../scripts/calculateDateStatus';
import hightlight from '../../../scripts/hightlighter';
import tableScroll from '../../../scripts/tableScroll';
import unauthorizedRedirection from '../../../scripts/unauthorizedRedirection';
import { socket } from '../../../App';

//------------------------------------------------------------------------------------

let searchTextField = '';

const Vendors = () => {
    unauthorizedRedirection();
    document.title = `View vendors • Pune Division Catering Vendor Id`;

    const [vendors, setVendors] = useState([]);
    const [notFound, setNotFound] = useState(true);
    const [buffer, setBuffer] = useState(true);
    const owner = JSON.parse(localStorage.getItem('owner'));
    const [vendorAddStatus, setVendorAddStatus] = useState({
        added: {
            pfVendorCount: 0,
            stallTrolleyVendorCount: 0,
            supportingStaff: 0,
        },
        total: {
            pfVendorCount: 0,
            stallTrolleyVendorCount: 0,
            supportingStaff: 0,
        },
    });
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [fetchComplete, setFetchComplete] = useState(false);

    const [vendorsTotalItems, setVendorsTotalItems] = useState(0);
    const [vendorsItemsPerPage, setVendorsItemsPerPage] = useState(20);
    const [vendorsActivePage, setVendorsActivePage] = useState(1);

    let accessToken = owner.accessToken;

    const getVendors = async () => {
        try {
            const res = await axios.get(`${baseUrl}/vendors/${owner.id}`, {
                headers: {
                    'access-token': accessToken,
                },
            });

            if (!auth(res.data.message)) return;

            if (res.data) {
                const arrayData = [];
                for (const data of res.data) {
                    arrayData.unshift(data);
                }

                let array = arrayData;
                let mainArray = [];

                let n = 0;
                for (
                    let i = 0;
                    i < Math.ceil(array.length / vendorsItemsPerPage);
                    i++
                ) {
                    mainArray.push([]);
                    for (let a = 0; a < vendorsItemsPerPage; a++) {
                        if (array[n] != undefined) {
                            mainArray[i].push(array[n]);
                            n++;
                        }
                    }
                }

                setVendors(mainArray);
                setVendorsTotalItems(res.data.length);

                setNotFound(false);
            } else {
                setNotFound(true);
            }

            setBuffer(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getVendorAddStatus = async () => {
        let accessToken = owner.accessToken;

        try {
            const res = await axios.get(
                `${baseUrl}/owners/vendorAddStatus/${owner.id}`,
                {
                    headers: {
                        'access-token': accessToken,
                    },
                }
            );

            if (!auth(res.data.message)) return;

            if (res.data) {
                setVendorAddStatus((prev) => ({ ...prev, ...res.data }));
                setNotFound(false);
            } else {
                setNotFound(true);
            }
            setBuffer(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setIsRefreshing(true);

        getVendorAddStatus();
        getVendors();

        setTimeout(() => {
            setTimeout(() => {
                setFetchComplete(false);
            }, 2000);
            setFetchComplete(true);
            setIsRefreshing(false);
        }, 1000);

        socket.on('isVendorUpdated', (data) => {
            if (searchTextField.length === 0) {
                getVendors();
            }
        });

        socket.on('isOwnerUpdated', (data) => {
            getVendorAddStatus();
        });
    }, []);

    //---------------------------------------------------------

    const [dialog, setDialog] = useState(false);

    const openDialog = (index) => {
        setDialog(vendors[vendorsActivePage - 1][index]);
    };

    const closeDialog = () => {
        setDialog(false);
    };

    //---------------------------------------------------------

    const disableVendor = async (vendorId, vendorName) => {
        let accessToken = JSON.parse(localStorage.getItem('owner')).accessToken;

        if (
            window.confirm(
                `Are you sure you want to deactivate this vendor with name '${vendorName}'? This process is non-reversal.`
            )
        ) {
            // if (
            //     window.prompt(
            //         `Enter vendor name '${vendorName}' to deactivate.`
            //     ) !== vendorName
            // ) {
            //     alert('Wrong input. Vendor not deactivated');
            //     return;
            // }

            try {
                setNotFound(true);
                setBuffer(true);
                let data = {
                    updatedBy: 'owners',
                    updaterId: JSON.parse(localStorage.getItem('owner')).id,
                };

                const res = await axios.put(
                    `${baseUrl}/vendors/disable/${vendorId}`,
                    data,
                    {
                        headers: {
                            'access-token': accessToken,
                        },
                    }
                );

                if (!auth(res.data.message)) return;

                if (res.data) {
                    setIsRefreshing(true);

                    getVendors();
                    getVendorAddStatus();
                    setNotFound(false);

                    setTimeout(() => {
                        setTimeout(() => {
                            setFetchComplete(false);
                        }, 2000);
                        setFetchComplete(true);
                        setIsRefreshing(false);
                    }, 1000);

                    socket.emit('updateVendors', {
                        message: 'updateVendors',
                    });
                } else {
                    setNotFound(true);
                }
                setBuffer(false);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const search = async () => {
        if (searchText.search.length !== 0 && searchText.search.length < 255) {
            try {
                setNotFound(true);
                setBuffer(true);

                const ownerId = JSON.parse(localStorage.getItem('owner')).id;

                const res = await axios.post(
                    `${adminBaseUrl}/vendors-under-this-owner/search/${ownerId}`,
                    searchText,
                    {
                        headers: {
                            'access-token': accessToken,
                        },
                    }
                );

                if (!auth(res.data.message)) return;

                if (res.data) {
                    if (res.data.length === 0) {
                        setNotFound(false);
                        setBuffer(false);
                        setTimeout(() => {
                            alert(`No results for "${searchText.search}".`);
                        }, 10);
                        return;
                    }

                    const arrayData = [];
                    for (const data of res.data) {
                        arrayData.unshift(data);
                    }

                    let array = arrayData;
                    let mainArray = [];

                    let n = 0;
                    for (
                        let i = 0;
                        i < Math.ceil(array.length / vendorsItemsPerPage);
                        i++
                    ) {
                        mainArray.push([]);
                        for (let a = 0; a < vendorsItemsPerPage; a++) {
                            if (array[n] != undefined) {
                                mainArray[i].push(array[n]);
                                n++;
                            }
                        }
                    }

                    setVendors(mainArray);
                    setVendorsTotalItems(res.data.length);
                    setNotFound(false);
                } else {
                    setNotFound(true);
                }

                setBuffer(false);
                searchTextField = searchText.search;
                hightlight(searchText.search);

                // searchTextField = searchText.search;
            } catch (err) {
                console.log(err);
            }
        } else if (
            searchText.search.length !== 0 &&
            searchText.search.length > 255
        ) {
            alert('Search text is too big to search.');
        } else {
            alert("Can't search, search field is empty.");
        }
    };

    // const getRemainingDays = (endingDate) => {
    //     const date1 = new Date();
    //     const date2 = new Date(endingDate);
    //     const diffTime = Math.abs(date2 - date1);
    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    //     if ((date2 - date1).toString()[0] == '-') {
    //         return {
    //             className: 'warning-container strong',
    //             label: `Vendor's validity expired ${diffDays} days ago. Please renew vendor.`,
    //         };
    //     }

    //     if (diffDays < 1) {
    //         return {
    //             className: 'warning-container strong',
    //             label: `Vendor's validity expired. Please renew vendor.`,
    //         };
    //     }

    //     if (diffDays < 15) {
    //         return {
    //             className: 'warning-container strong',
    //             label: `${diffDays} days remained. Please renew vendor.`,
    //         };
    //     }

    //     return {
    //         className: 'warning-container',
    //         label: `${diffDays} days remained.`,
    //     };
    // }

    const [searchText, setSearchText] = useState({
        search: '',
    });

    const enterClick = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            let button = document.getElementById('on-enter');
            button.click();
        }
    };

    const handleChange = (e) => {
        setSearchText((prev) => ({ ...prev, search: e.target.value }));
    };

    const getPageNumberVendors = (number) => {
        setVendorsActivePage(number);
    };

    // const totalCount = () => {
    // 	let count = 0;
    // 	for (let i = 0; i < vendors.length; i++) {
    // 		count = count + vendors[i].length;
    // 	}
    // 	return count;
    // };

    useEffect(() => {
        document.addEventListener('keyup', (e) => {
            if (e.key !== '/') return;
            let searchInput = document.getElementById('search-input');
            searchInput.focus();
        });
    }, []);

    return (
        <>
            <Header />
            <div className='screen-container'>
                <Banner />
                <div className='side-container'>
                    <Navigation />
                    <div className='side-content'>
                        {buffer && notFound ? <Buffer /> : <></>}

                        {vendors.length === 0 && !buffer ? (
                            <Fallback
                                title='Not found'
                                text='No data found in the database. Try refreshing the page.'
                            />
                        ) : (
                            <></>
                        )}

                        {vendors.length !== 0 && !notFound && !buffer ? (
                            <>
                                <div className='screen-title headline-large'>
                                    Vendors
                                </div>

                                <div className='active-vendors-counts-container'>
                                    <div className='active-vendors-counts'>
                                        <div className='value headline-large'>
                                            {
                                                vendorAddStatus.added
                                                    .stallTrolleyVendorCount
                                            }
                                            <span>
                                                /
                                                {
                                                    vendorAddStatus.total
                                                        .stallTrolleyVendorCount
                                                }
                                            </span>
                                        </div>
                                        <div className='title title-small'>
                                            Number of staff
                                        </div>
                                    </div>
                                    <div className='active-vendors-counts'>
                                        <div className='value headline-large'>
                                            {
                                                vendorAddStatus.added
                                                    .pfVendorCount
                                            }
                                            <span>
                                                /
                                                {
                                                    vendorAddStatus.total
                                                        .pfVendorCount
                                                }
                                            </span>
                                        </div>
                                        <div className='title title-small'>
                                            PF vendor
                                        </div>
                                    </div>
                                    <div className='active-vendors-counts'>
                                        <div className='value headline-large'>
                                            {
                                                vendorAddStatus.added
                                                    .supportingStaff
                                            }
                                            <span>
                                                /
                                                {
                                                    vendorAddStatus.total
                                                        .supportingStaff
                                                }
                                            </span>
                                        </div>
                                        <div className='title title-small'>
                                            Supporting staff
                                        </div>
                                    </div>
                                    <div className='active-vendors-counts total'>
                                        <div className='value headline-large'>
                                            {vendorAddStatus.added
                                                .stallTrolleyVendorCount +
                                                vendorAddStatus.added
                                                    .pfVendorCount +
                                                vendorAddStatus.added
                                                    .supportingStaff}
                                            <span>
                                                /
                                                {vendorAddStatus.total
                                                    .stallTrolleyVendorCount +
                                                    vendorAddStatus.total
                                                        .pfVendorCount +
                                                    vendorAddStatus.total
                                                        .supportingStaff}
                                            </span>
                                        </div>
                                        <div className='title title-small'>
                                            Total vendors
                                        </div>
                                    </div>
                                </div>

                                <div className='search-container'>
                                    <input
                                        type='text'
                                        className='body-medium'
                                        id='search-input'
                                        name='search'
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        placeholder='Search here (press "/" to focus)'
                                        onKeyUp={(event) => enterClick(event)}
                                        defaultValue={searchText.search}
                                    />
                                    <button
                                        disabled={isRefreshing}
                                        className={
                                            isRefreshing
                                                ? 'icon-button refreshing'
                                                : 'icon-button'
                                        }
                                        onClick={() => {
                                            searchTextField = '';
                                            setSearchText((prev) => ({
                                                ...prev,
                                                search: '',
                                            }));

                                            document.getElementById(
                                                'search-input'
                                            ).value = '';

                                            setIsRefreshing(true);

                                            getVendors();

                                            setTimeout(() => {
                                                setTimeout(() => {
                                                    setFetchComplete(false);
                                                }, 2000);
                                                setFetchComplete(true);
                                                setIsRefreshing(false);
                                            }, 1000);
                                        }}
                                        title='Reload'
                                    >
                                        {fetchComplete ? (
                                            <CheckCircle />
                                        ) : (
                                            <Refresh />
                                        )}
                                    </button>
                                    <button
                                        type='button'
                                        id='on-enter'
                                        className='filled-tonal-button'
                                        onClick={search}
                                    >
                                        Search
                                    </button>
                                </div>

                                <div className='vendors-table'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className='border-left-none left-box'>
                                                    Sr.&nbsp;No.
                                                </th>
                                                <th>Photo</th>
                                                <th>Full&nbsp;name</th>
                                                {/* <th>Vendor&nbsp;Type</th> */}
                                                {/* <th>Aadhaar&nbsp;number</th>
                                                <th>Id&nbsp;number</th>
                                                <th>Designation</th>
                                                <th>Unit</th>
                                                <th>Date&nbsp;of&nbsp;issue</th>
                                                <th>Valid&nbsp;upto</th>
                                                <th>Valid&nbsp;for&nbsp;platform</th> */}
                                                <th>Designation</th>
                                                <th>
                                                    Station&nbsp;Manager&nbsp;approval
                                                </th>
                                                <th>CSCI&nbsp;approval</th>
                                                <th>IPF&nbsp;approval</th>
                                                <th>
                                                    Sr.&nbsp;DCM&nbsp;approval
                                                </th>
                                                <th>Vendor&nbsp;status</th>
                                                <th className='right-box'>
                                                    View&nbsp;vendor
                                                </th>
                                            </tr>
                                        </thead>
                                        {vendors[vendorsActivePage - 1].map(
                                            (vendor, index) => {
                                                let className =
                                                    vendor.isDisabled == '1'
                                                        ? 'disabled-row'
                                                        : '';
                                                let dotClassName = 'dot';

                                                if (
                                                    vendor.stMaster != 0 &&
                                                    vendor.ci != 0 &&
                                                    vendor.ipf != 0 &&
                                                    vendor.srdcm != 0 &&
                                                    vendor.isDisabled == '0'
                                                ) {
                                                    className = 'success-row';
                                                    dotClassName =
                                                        'dot success';
                                                }

                                                return (
                                                    <tbody
                                                        key={Math.random()}
                                                        title={`${vendor.fullname} (${vendor.station})`}
                                                    >
                                                        <tr className='gap'></tr>
                                                        <tr
                                                            className={
                                                                className
                                                            }
                                                        >
                                                            <td
                                                                className='center left-box'
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        dotClassName
                                                                    }
                                                                ></div>
                                                                {(vendorsActivePage -
                                                                    1) *
                                                                    vendorsItemsPerPage +
                                                                    (index + 1)}
                                                            </td>
                                                            <td
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );

                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                            >
                                                                <div className='table-profile-photo'>
                                                                    <img
                                                                        src={
                                                                            vendor.photo
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                            {/* <td className='hover'><img className='img' src={vendor.photo} alt='' /></td> */}
                                                            <td
                                                                className='hightlight'
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                            >
                                                                {vendor.fullname ||
                                                                    'Unknown'}
                                                            </td>
                                                            <td
                                                                className='hightlight'
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                            >
                                                                {vendor.desigination ||
                                                                    'Unknown'}
                                                            </td>
                                                            {/* <td>{vendor.vendorType || 'Unknown'}</td> */}
                                                            {/* <td>{vendor.aadhaar || 'Unknown'}</td>
                                                        <td>{vendor.idNumber || 'Unknown'}</td>
                                                        <td>{vendor.desigination || 'Unknown'}</td>
                                                        <td>{vendor.unit || 'Unknown'}</td>
                                                        <td>{vendor.issueDate || 'Unknown'}</td>
                                                        <td>{vendor.validUpto || 'Unknown'}</td>
                                                        <td>{vendor.validFor || 'Unknown'}</td> */}

                                                            <td
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                                className={
                                                                    vendor.stMaster !=
                                                                    0
                                                                        ? 'success-background center'
                                                                        : 'error-background center'
                                                                }
                                                            >
                                                                {vendor.stMaster !=
                                                                0 ? (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                true
                                                                            }
                                                                        />
                                                                        Approved
                                                                    </div>
                                                                ) : (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                false
                                                                            }
                                                                        />
                                                                        Not
                                                                        approved
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                                className={
                                                                    vendor.ci !=
                                                                    0
                                                                        ? 'success-background center'
                                                                        : 'error-background center'
                                                                }
                                                            >
                                                                {vendor.ci !=
                                                                0 ? (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                true
                                                                            }
                                                                        />
                                                                        Approved
                                                                    </div>
                                                                ) : (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                false
                                                                            }
                                                                        />
                                                                        Not
                                                                        approved
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                                className={
                                                                    vendor.ipf !=
                                                                    0
                                                                        ? 'success-background center'
                                                                        : 'error-background center'
                                                                }
                                                            >
                                                                {vendor.ipf !=
                                                                0 ? (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                true
                                                                            }
                                                                        />
                                                                        Approved
                                                                    </div>
                                                                ) : (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                false
                                                                            }
                                                                        />
                                                                        Not
                                                                        approved
                                                                    </div>
                                                                )}
                                                            </td>
                                                            <td
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                                className={
                                                                    vendor.srdcm !=
                                                                    0
                                                                        ? 'success-background center'
                                                                        : 'error-background center'
                                                                }
                                                            >
                                                                {vendor.srdcm !=
                                                                0 ? (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                true
                                                                            }
                                                                        />
                                                                        Approved
                                                                    </div>
                                                                ) : (
                                                                    <div className='approval-status'>
                                                                        <CheckUncheck
                                                                            isChecked={
                                                                                false
                                                                            }
                                                                        />
                                                                        Not
                                                                        approved
                                                                    </div>
                                                                )}
                                                            </td>

                                                            <td
                                                                className='hover'
                                                                onClick={() => {
                                                                    if (
                                                                        vendor.isDisabled ==
                                                                        '0'
                                                                    ) {
                                                                        disableVendor(
                                                                            vendor.id,
                                                                            vendor.fullname
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {vendor.isDisabled ==
                                                                0 ? (
                                                                    <button className='text-button'>
                                                                        Click to
                                                                        deactivate
                                                                    </button>
                                                                ) : (
                                                                    'Deactivated'
                                                                )}
                                                            </td>
                                                            <td
                                                                className='hover right-box'
                                                                onClick={() => {
                                                                    openDialog(
                                                                        index
                                                                    );
                                                                    // if (vendor.isDisabled == '0') {
                                                                    //     openDialog(index);
                                                                    // }
                                                                }}
                                                            >
                                                                <button className='text-button'>
                                                                    View vendor
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                );
                                            }
                                        )}
                                    </table>
                                </div>

                                <Pagination
                                    getTotalItems={vendorsTotalItems}
                                    getItemsPerPage={vendorsItemsPerPage}
                                    getPageNumber={getPageNumberVendors}
                                    activePage={vendorsActivePage}
                                />
                            </>
                        ) : (
                            <></>
                        )}

                        <Footer />
                    </div>
                </div>
            </div>

            {dialog && (
                <div className='dialog-container'>
                    <dialog
                        open
                        className='dialog'
                    >
                        <div className='dialog-top'>
                            <div className='title title-large'>
                                Vendor details
                            </div>
                            <button
                                className='icon-button'
                                onClick={closeDialog}
                            >
                                <Clear />
                            </button>
                        </div>

                        <div className='dialog-content'>
                            <div className='container-1'>
                                <div className='profile-image'>
                                    <img
                                        className='img'
                                        src={dialog.photo}
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className='label label-medium'>
                                Permission letter
                            </div>

                            <a
                                rel='noopener noreferrer'
                                href={dialog.pdf}
                                target='_blank'
                            >
                                <button
                                    className='outlined-button'
                                    style={{
                                        width: 'calc(50% - (var(--space) / 2))',
                                    }}
                                >
                                    {dialog.pdf
                                        ? 'Click to view permission letter'
                                        : 'Permission letter not found'}
                                </button>
                            </a>

                            <div
                                className={
                                    calculateDateStatus(dialog.validUpto)
                                        .className
                                }
                            >
                                {calculateDateStatus(dialog.validUpto).message}
                            </div>

                            <div className='container-3'>
                                <div className='label label-medium'>
                                    Full name
                                </div>
                                <div className='full-name title-large'>
                                    {dialog.fullname}
                                </div>

                                <div className='label label-medium'>
                                    Aadhaar number
                                </div>
                                <div className='id-number body-large'>
                                    {dialog.aadhaar}
                                </div>

                                <div className='details-container-1'>
                                    <div>
                                        <div className='label label-medium'>
                                            Id number
                                        </div>
                                        <div className='aadhaar-id body-large'>
                                            {dialog.idNumber}
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>
                                            Designation
                                        </div>
                                        <div className='valid-upto-date body-large'>
                                            {dialog?.desigination}
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>
                                            Unit
                                        </div>
                                        <div className='valid-for body-large'>
                                            {dialog.unit}
                                        </div>
                                    </div>
                                </div>

                                <div className='details-container-1'>
                                    <div>
                                        <div className='label label-medium'>
                                            Date of issue
                                        </div>
                                        <div className='designation body-large'>
                                            {`${new Date(
                                                dialog.issueDate
                                            ).getDate()}-${
                                                new Date(
                                                    dialog.issueDate
                                                ).getMonth() + 1
                                            }-${new Date(
                                                dialog.issueDate
                                            ).getFullYear()}`}
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>
                                            Valid upto
                                        </div>
                                        <div className='unit body-large'>
                                            {`${new Date(
                                                dialog.validUpto
                                            ).getDate()}-${
                                                new Date(
                                                    dialog.validUpto
                                                ).getMonth() + 1
                                            }-${new Date(
                                                dialog.validUpto
                                            ).getFullYear()}`}
                                        </div>
                                    </div>

                                    <div>
                                        <div className='label label-medium'>
                                            Valid for platform
                                        </div>
                                        <div className='issue-date body-large'>
                                            {dialog.validFor}
                                        </div>
                                    </div>
                                </div>

                                <div className='details-container-1'>
                                    <div>
                                        <div className='label label-medium'>
                                            Station
                                        </div>
                                        <div className='designation body-large'>
                                            {dialog.station
                                                ? dialog.station
                                                : '--'}
                                        </div>
                                    </div>

                                    {/* <div>
                                        <div className='label label-medium'>Vendor Type</div>
                                        <div className='designation body-large'>{dialog.vendorType ? dialog.vendorType : '--'}</div>
                                    </div> */}

                                    <div>
                                        <div className='label label-medium'></div>
                                        <div className='designation body-large'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}
        </>
    );
};

export default Vendors;

//------------------------------------------------------------------------------------
