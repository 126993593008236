//------------------------------------------------------------------------------------

import './Home.scss';

import { useEffect, useState } from 'react';

import Banner from '../../../components/Banner/Banner';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import Id from '../../../assets/id.jpg';
import { Navigate } from 'react-router-dom';
import Profile from '../../../assets/profile.jpg';
import Shop from '../../../assets/shop.jpg';
import Signature from '../../../assets/signature.png';
import Stamp from '../../../assets/stamp.png';
import checkLoginStatus from '../../../scripts/checkLoginStatus';

//------------------------------------------------------------------------------------

const Home = () => {
	document.title = `User Panel • Pune Division Catering Vendor Id`;

	const [validLogin, setValidLogin] = useState(false);

	useEffect(() => {
		status();
	}, []);

	const status = async () => {
		if (await checkLoginStatus()) {
			setValidLogin(true);
		} else {
			setValidLogin(false);
		}
	};

	return (
		<>
			<Header />
			<div className='screen-container'>
				<Banner />
				<div className='instruction-title display-small'>
					फॉर्म भरने के निर्देश
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						1. कृपया लाइसेंसी अपना पासपोर्ट साइज फोटो दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='image-container'>
						<img src={Profile} />
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						2. कृपया आईडी प्रूफ दर्ज करें।
					</div>
					<div className='example-text label-small'>
						उदाहरण:आधार कार्ड, वोटर आयडी{' '}
					</div>
					<div className='image-container'>
						<img src={Id} />
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						3. स्टॉल की नेम प्लेट के साथ लाइसेंसी का फोटो दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='image-container'>
						<img src={Shop} />
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						4. कृपया लाइसेंसी के हस्ताक्षर की फोटो दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='image-container'>
						<img src={Signature} />
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						5. कृपया स्टॉल के स्टॅम्प का फोटो दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='image-container'>
						<img src={Stamp} />
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						6. कृपया आधार कार्ड के अनुसार लाइसेंसी का नाम दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>Amar Shantilal Sharma</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						7. कृपया स्टॉल का नाम दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>Sharma & Co.</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						8. कृपया स्टॉल का लोकेशन दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>
						{' '}
						CSTM End, Platform No 1/2, Bhusawal, Maharashtra.
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						9. कृपया ई-मेल दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>example@gmail.com</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						10. कृपया लाइसेंसी अपना मोबाइल नंबर दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>987643210</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						11. कृपया स्टॉल शुरू होने की तिथि दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>
						01-03-{new Date().getFullYear()}
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						12. कृपया स्टॉल वैधता तिथि दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>
						01-06-{new Date().getFullYear()}
					</div>
				</div>

				<div className='instructions-contaner'>
					<div className='instruction title-large'>
						13. कृपया एक सुरक्षित पासवर्ड बनाएं और दर्ज करें।
					</div>
					<div className='example-text label-small'>उदाहरण:</div>
					<div className='dummy-example body-large'>Password@123</div>
				</div>

				{/* <div className="side-container">
                    <div className="textual-instructions">
                        <div className="instructions-title headline-medium">फार्म भरने के निर्देश</div>
                        <li className="instruction body-large">कृपया निर्दिष्ट आवश्यकताओं के अनुरूप उपयोगकर्ता की तस्वीर डालें ।</li>
                        <li className="instruction body-large">कृपया निर्दिष्ट आवश्यकताओं के अनुरूप उपयोगकर्ता हस्ताक्षर की छवि डालें । </li>
                        <li className="instruction body-large">
                            कृपया आईडी प्रूफ ,मालिक की फोटो, दुकान की नेम प्लेट के साथ दुकान के मालिक की फोटो, दुकान का स्टाम्प छाप, फोटो प्रमाण पत्र की स्कैन की हुई प्रति डाले ।
                        </li>
                    </div>
                    <div className="visual-instructions">
                        <iframe width="100%" className="h-100" src="" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen style={{ aspectRatio: '16 / 9' }}></iframe>
                    </div>
                </div> */}
				<Footer />
			</div>
			{validLogin && (
				<Navigate
					replace
					to='/home/profile'
				/>
			)}
		</>
	);
};

export default Home;

//------------------------------------------------------------------------------------
