const getUpcomingYears = () => {
    const yearsCount = 1;
    const currentYear = new Date().getFullYear();

    const nextYears = [];

    for (let i = 1; i <= yearsCount; i++) {
        nextYears.push(currentYear + i);
    }

    return nextYears;
}

export default getUpcomingYears;