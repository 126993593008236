//------------------------------------------------------------------------------------

import '../external.scss';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import { useNavigate } from 'react-router-dom';

//------------------------------------------------------------------------------------

const otpValidation = () => {
    const otpNumber = document.getElementById('otp');
    const otpNumberHint = document.getElementById('otp-hint');
    const otpNumberRegex = /^\d{4}$/;
    if (otpNumber.value.match(otpNumberRegex)) {
        otpNumber.parentElement?.classList.remove('error');
        otpNumberHint.innerHTML = '&nbsp;';
        return true;
    } else {
        otpNumber.parentElement?.classList.add('error');
        otpNumberHint.innerHTML = 'Please enter valid otp number';
        return false;
    }
}

const Otp = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState({
        email: state.email,
        otp: '',
    });

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const verifyOtp = async () => {
        try {
            const res = await axios.post(`${baseUrl}/otp`, data);
            if (res.data) {
                navigate(
                    '/reset-password',
                    { state: { email: data.email } }
                );
            } else {
                alert('Incorrect OTP, please try again.');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const validate = () => {
        otpValidation();

        if (otpValidation()) {
            verifyOtp();
        }
    }

    return (
        <>
            <div className='external-screen'>
                <div className="frame">
                    <div className="outlined-card">
                        <form action="">
                            <div className="app-title headline-small">OTP</div>
                            <div className='data-preview body-small'>{state.email}</div>

                            <div className="input-container">
                                <input type="number" id='otp' name='otp' className='inputfield' placeholder='Otp' onChange={handleChange} onBlur={otpValidation} />
                                <div id='otp-hint' className="input-hints">&nbsp;</div>
                            </div>

                            <div className="bottom-buttons">
                                <button type='button' className='text-button'>Resend</button>
                                <button type='button' className='filled-button' onClick={validate}>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Otp;

//------------------------------------------------------------------------------------

