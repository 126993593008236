//------------------------------------------------------------------------------------

import './styles/globals.scss';
import './styles/colors.scss';
import './styles/typography.scss';
import './styles/buttons.scss';
import './styles/inputs.scss';
import './styles/cards.scss';
import './styles/keyframes.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ForgetPassword from './screens/external/ForgetPassword/ForgetPassword';
import Home from './screens/internal/Home/Home';
import Login from './screens/external/Login/Login';
import NotFound from './screens/external/NotFound/NotFound';
import OnlineStatus from './components/OnlineStatus/OnlineStatus';
import Otp from './screens/external/Otp/Otp';
import Owner from './screens/external/Register/Owner/Owner';
import Payment from './screens/internal/Payment/Payment';
import Profile from './screens/internal/Profile/Profile';
import ResetPassword from './screens/external/ResetPassword/ResetPassword';
import Vendor from './screens/external/Register/Vendor/Vendor';
import Vendors from './screens/internal/Vendors/Vendors';
import tableScroll from './scripts/tableScroll';
import theme from './scripts/theme';
// import Intro from "./screens/external/Intro/Intro";
import { useEffect } from 'react';
import io from 'socket.io-client';
import { adminBaseUrl } from './scripts/baseUrl';

export const socket = io.connect(adminBaseUrl);

//------------------------------------------------------------------------------------

const App = () => {
    useEffect(() => {
        theme.set();
        tableScroll.horizontal('vendors-table');
        tableScroll.vertical('side-content');
    }, []);

    return (
        <div className='app'>
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/register/vendor'
                        element={<Vendor />}
                    />
                    <Route
                        path='/register/owner'
                        element={<Owner />}
                    />
                    <Route
                        path='/login'
                        element={<Login />}
                    />
                    <Route
                        path='/forget-password'
                        element={<ForgetPassword />}
                    />
                    <Route
                        path='/otp'
                        element={<Otp />}
                    />
                    <Route
                        path='/reset-password'
                        element={<ResetPassword />}
                    />

                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path='/home'
                        element={<Home />}
                    />
                    <Route
                        path='/home/profile'
                        element={<Profile />}
                    />
                    <Route
                        path='/home/vendors'
                        element={<Vendors />}
                    />

                    <Route
                        path='/owner/payment'
                        element={<Payment />}
                    />
                    <Route
                        path='/vendor/payment'
                        element={<Payment />}
                    />

                    <Route
                        path='*'
                        element={<NotFound />}
                    />
                </Routes>
            </BrowserRouter>
            <OnlineStatus />
        </div>
    );
};

export default App;

//------------------------------------------------------------------------------------
