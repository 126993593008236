//------------------------------------------------------------------------------------

import { useState, useEffect } from 'react';
import './Banner.scss';
import banner from '../../assets/banner.jpg';
import bannerlogo from '../../assets/bannerlogo.png'

//------------------------------------------------------------------------------------

const images = [
    {
        src: bannerlogo,
    }
];

const Banner = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const interval = 5000;
    let gridColumns = '';
    for (const iterator of images) {
        gridColumns = gridColumns + 'auto ';
    }

    useEffect(() => {
        const bannerContainer = document.getElementById('banner-container');
        const windowWidth = window.innerWidth;
        const maxScrolls = images.length;
        let scrollLeft = true;
        let scrollX = 0;

        setInterval(() => {
            bannerContainer.scrollLeft = `${windowWidth * scrollX + 1}`;
            if (scrollX < maxScrolls && scrollLeft) {
                scrollX++;
                if (scrollX === (maxScrolls - 1)) {
                    scrollLeft = false;
                }
            } else {
                scrollX--;
                if (scrollX === 0) {
                    scrollLeft = true;
                }
            }
            setTimeout(() => {
                setScrollPosition(scrollX);
            }, interval - 500);
        }, interval);
    }, []);

    return (
        <div className='banner-images-container'>
            <div className='banner-container' id='banner-container'  >
                {
                    images.map((image, index) => {
                        return (
                            <div key={index} className='banner' id={index} >
                                <img src={image.src} />
                            </div>
                        )
                    })
                }
            </div>
            {/* <div className='scroll-dots-container'>
                {
                    images.map((image, index) => {
                        let className = scrollPosition === index ? 'scroll-dot active' : 'scroll-dot';
                        return <div key={index} className={className}></div>
                    })
                }
            </div> */}
        </div>
    )
}

export default Banner;

//------------------------------------------------------------------------------------
