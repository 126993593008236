//------------------------------------------------------------------------------------

let scrollSize = 200;

let isVerticalScroll = true;
let isHorizontalScroll = true;

const tableScroll = {
	vertical: (className) => {
		document
			.getElementsByTagName('html')[0]
			.addEventListener('keydown', (e) => {
				if (!isVerticalScroll) return;

				isVerticalScroll = false;

				let scrollContainer =
					document.getElementsByClassName(className)[0];

				if (scrollContainer == undefined) return;

				if (e.keyCode == '38') {
					scrollContainer.scrollTop =
						scrollContainer.scrollTop - scrollSize;
				} else if (e.keyCode == '40') {
					scrollContainer.scrollTop =
						scrollContainer.scrollTop + scrollSize;
				}

				setTimeout(() => {
					isVerticalScroll = true;
				}, 300);
			});
	},
	horizontal: (className) => {
		document
			.getElementsByTagName('html')[0]
			.addEventListener('keydown', (e) => {
				if (!isHorizontalScroll) return;

				isHorizontalScroll = false;

				let scrollContainer =
					document.getElementsByClassName(className)[0];

				if (scrollContainer == undefined) return;

				if (e.keyCode == '37') {
					scrollContainer.scrollLeft =
						scrollContainer.scrollLeft - scrollSize;
				} else if (e.keyCode == '39') {
					scrollContainer.scrollLeft =
						scrollContainer.scrollLeft + scrollSize;
				}

				setTimeout(() => {
					isHorizontalScroll = true;
				}, 300);
			});
	},
};

export default tableScroll;

//------------------------------------------------------------------------------------
