//------------------------------------------------------------------------------------

import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../external.scss';
import axios from 'axios';
import baseUrl from '../../../scripts/baseUrl';
import { useNavigate } from 'react-router-dom';

//------------------------------------------------------------------------------------

const newPasswordValidation = () => {
    const password = document.getElementById('new-password');
    const passwordHint = document.getElementById('new-password-hint');
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (password.value.match(passwordRegex)) {
        password.parentElement?.classList.remove('error');
        passwordHint.innerHTML = '&nbsp;';
        return true;
    } else {
        password.parentElement?.classList.add('error');
        passwordHint.innerHTML = 'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
        return false;
    }
}

const confirmPasswordValidation = () => {
    const password = document.getElementById('confirm-password');
    const passwordHint = document.getElementById('confirm-password-hint');
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (password.value.match(passwordRegex)) {
        password.parentElement?.classList.remove('error');
        passwordHint.innerHTML = '&nbsp;';
        return true;
    } else {
        password.parentElement?.classList.add('error');
        passwordHint.innerHTML = 'Please enter password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';
        return false;
    }
}

const isSamePassword = () => {
    const newPassword = document.getElementById('new-password');
    const confirmPassword = document.getElementById('confirm-password');

    if (newPassword.value == confirmPassword.value) {
        return true;
    } else {
        alert('Both password must be same.');
        return false;
    }
}

const ResetPassword = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [data, setData] = useState({
        email: state.email,
    });

    const handleChange = (e) => {
        setData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const resetPassword = async () => {
        try {
            const res = await axios.post(`${baseUrl}/changePassword`, data);
            if (res.data) {
                alert('Password has been changed.');
                navigate('/login');
            } else {
                alert('Failed to change password.');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const validate = () => {
        newPasswordValidation();
        confirmPasswordValidation();
        isSamePassword();

        if (newPasswordValidation() &&
            confirmPasswordValidation() &&
            isSamePassword()
        ) {
            resetPassword();
        }
    }

    return (
        <>
            <div className='external-screen'>
                <div className="frame">
                    <div className="outlined-card">
                        <form action="">
                            <div className="app-title headline-small">Reset Password</div>
                            <div className='data-preview body-small'>{data.email}</div>

                            <div className="input-container">
                                <input type="password" id='new-password' name='newPassword' className='inputfield' placeholder='New password' maxLength={255} onChange={handleChange} onBlur={newPasswordValidation} />
                                <div id='new-password-hint' className="input-hints">&nbsp;</div>
                            </div>

                            <div className="input-container">
                                <input type="password" id='confirm-password' name='confirmPassword' className='inputfield' placeholder='Confirm password' maxLength={255} onChange={handleChange} onBlur={confirmPasswordValidation} />
                                <div id='confirm-password-hint' className="input-hints">&nbsp;</div>
                            </div>

                            <div className="bottom-buttons">
                                <div></div>
                                <button type='button' className='filled-button' onClick={validate}>Next</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ResetPassword;

//------------------------------------------------------------------------------------

